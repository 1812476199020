import React, {createRef} from "react";

import { Hero, About, Nav, Services, Contact, Footer } from "@views/index";

import styles from "./home.module.scss";

const Home = () => {
  const servicesRef: any = createRef();
  const aboutRef: any = createRef();
  const contactRef: any = createRef();

  const executeScroll = (type: string) => {
    switch (type) {
      case "services":
        servicesRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "about":
        aboutRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "contact":
        contactRef.current.scrollIntoView({ behavior: "smooth" });
        break;
    }
  };

  return (
    <div>
      <div className={styles.menu}>
        <Nav executeScroll={executeScroll} />
        <Hero executeScroll={executeScroll} />
      </div>
      <div ref={servicesRef}>
        <Services />
      </div>
      <div ref={aboutRef}>
        <About />
      </div>
      <div ref={contactRef}>
        <Contact />
      </div>
      <Footer />
    </div>
  );
}

export default Home;
