import React, {FC} from "react";

import logoImg from "@assets/logoGrey.png";

import styles from "./nav.module.scss";

interface INav {
  executeScroll: (type: string) => void;
}
const Nav: FC<INav> = ({ executeScroll }) => {
  return (
    <div className={styles.main}>
      <div className={styles.contact}>
        <p onClick={executeScroll.bind(this, "contact")}>¡Contáctanos!</p>
      </div>

      <button onClick={executeScroll.bind(this, "")}>Inicio</button>
      <div className={styles.bar} />
      <button onClick={executeScroll.bind(this, "services")}>Servicios</button>
      <div className={styles.bar} />
      <button onClick={executeScroll.bind(this, "about")}>Nosotros</button>
      <div className={styles.bar} />
      <button onClick={executeScroll.bind(this, "contact")}>Contacto</button>

      <img src={logoImg} alt=""/>
    </div>
  );
}

export default Nav;
