import React  from "react";
import {Field, Form, Formik} from "formik";

import {formSchema} from "@models/form.model";

import backgroundImg from "@assets/contact.jpg";

import styles from "./contact.module.scss";

const Contact = () => {
  const handleSubmit = (values: any) => {
    console.log(values)
  }

  return (
    <div className={styles.main}>
      <div className={styles.top} />

      <img src={backgroundImg} alt=""/>

      <div>
        <div>
          <div className={styles.bar} />

          <div>
            <h2>¿Tienes otra idea en mente?</h2>
            <h2>Platiquemos y la hacemos realidad</h2>

            <Formik
              initialValues={{} as FormType}
              validationSchema={formSchema}
              onSubmit={handleSubmit}
            >
              {({ isValid }) => (
                <Form>
                  <div>
                    <p>Nombre</p>
                    <Field name="name" autoComplete="off"/>
                  </div>

                  <div>
                    <p>Teléfono / Correo</p>
                    <Field name="phone" autoComplete="off"/>
                  </div>

                  <div>
                    <p>Cuéntanos más de tu proyecto</p>
                    <Field name="about" autoComplete="off"/>
                  </div>

                  <div>
                    <button type="submit" className={styles.button}>Enviar</button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
