import React  from "react";

import logoImg from "@assets/logo.png";

import styles from "./footer.module.scss";

const Footer = () => {

  return (
    <div className={styles.main}>
      <div>
        <div>
          <div className={styles.circle} />
          <span>Aviso de privacidad</span>
        </div>

        <div>
          <div className={styles.circle} />
          <span>contacto@izder.uy</span>
        </div>

        <div>
          <div className={styles.circle} />
          <span>009 1234 222</span>
        </div>
      </div>

      <div>
        <p>Paraguay 2141, 11800 Montevideo, Departamento de Montevideo, Uruguay.</p>
        <p>Torre 3, piso 1, oficina 14.</p>
      </div>

      <img src={logoImg} alt=""/>
    </div>
  );
}

export default Footer;
