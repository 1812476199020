import React, {FC} from "react";

import heroImg from "@assets/hero.jpg";

import styles from "./hero.module.scss";

interface IHero {
  executeScroll: (type: string) => void;
}
const Hero: FC<IHero> = ({ executeScroll }) => {

  return (
    <div className={styles.main}>
      <img src={heroImg} alt=""/>

      <div>
        <div>
          <div>
            <h1>¡Somos IZDER!</h1>

            <h2>Una empresa uruguaya de enfoque global con experiencia en el sector tecnológico y el desarrollo de plataformas.</h2>

            <div>
              <p onClick={executeScroll.bind(this, "contact")}>¡Contáctanos!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}

export default Hero;
