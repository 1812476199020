import React  from "react";

import backgroundImg from "@assets/background.jpg";
import compromiseImg from "@assets/compromise.jpg";
import qualityImg from "@assets/quality.jpg";
import cursorImg from "@assets/cursor.png";
import hamburgerImg from "@assets/hamburger.png";
import experienceImg from "@assets/experience.png";

import styles from "./about.module.scss";

const About = () => {

  return (
    <>
      <div className={styles.first}>

        <img src={backgroundImg} alt=""/>

        <div>
          <div>
            <div>
              <div className={styles.process}>
                <h2>Nuestro propósito</h2>
                <img className={styles.cursor} src={cursorImg} alt=""/>
              </div>
            </div>

            <div className={styles.text}>
              <p>Ser la única empresa a nivel mundial con plataformas digitales que ofrezcan una solución humana a cada necesidad de nuestros clientes.</p>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.second}>
        <h2>Nuestros Valores</h2>

        <div>
          <div className={styles.image}>
            <img src={compromiseImg} alt=""/>

            <div>
              <p className={styles.title}>Compromiso</p>

              <p className={styles.text}>Asumimos los productos que desarrollamos como propios, ofreciendo a nuestros clientes el 100% de nuestra capacidad.</p>
            </div>
          </div>
          <div className={styles.noImage}>
            <div>
              <p className={styles.title}>Experiencia</p>

              <img src={experienceImg} alt=""/>
            </div>
            <img className={styles.cursor} src={cursorImg} alt=""/>
          </div>
          <div className={styles.image}>
            <img src={qualityImg} alt=""/>

            <div>
              <p className={styles.title}>Calidad</p>

              <p className={styles.text}>Nuestros desarrollos evolucionan de acuerdo almercado y las necesidades de nuestros clientes.</p>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.third}>
        <div className={styles.top} />

        <h2>Nuestra Metodología</h2>

        <div className={styles.content}>
          <div className={styles.card}>
            <div>
              <img src={hamburgerImg} alt=""/>

              <p>Diseñamos a detalle tu plataforma</p>
            </div>

            <img className={styles.cursor} src={cursorImg} alt=""/>
          </div>


          <div className={styles.bar} />

          <div className={styles.card}>
            <div>
              <img src={hamburgerImg} alt=""/>

              <p>Elaboramos un documento técnico para especificar todas las funcionalidades incluidas en tu plataforma</p>
            </div>
          </div>


          <div className={styles.bar} />

          <div className={styles.card}>
            <div>
              <img src={hamburgerImg} alt=""/>

              <p>Una vez confirmado el diseño y funciones de la app, nuestros expertos empezarán a desarrollar las funciones de tu plataforma.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )

}

export default About;
